<template>
  <div v-if="this.feriado">
    <ConfirmDialog
      ref="confirmDialog"
      :dialogText="dialogText"
      :dialogTitle="dialogTitle"
      :dialogYes="dialogYes"
      :dialogNo="dialogNo"
      :id="this.feriado.id"
      @dialog-yes="handleDialogYes"
    ></ConfirmDialog>
    <v-card>
      <v-row>
        <v-col cols="8">
          <v-card-title class="person-name">
            {{ this.feriado.nombre }}
          </v-card-title>
          <v-card-subtitle>
            {{ this.feriado.get_tipo_display }}
          </v-card-subtitle>
        </v-col>
        <v-spacer> </v-spacer>
      </v-row>

      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <br />
            <p><strong>Nombre: </strong>{{ this.feriado.nombre }}</p>
            <p><strong>Tipo: </strong>{{ this.feriado.get_tipo_display }}</p>
            <p><strong>Fecha: </strong>{{ this.feriado.fecha }}</p>
            <p>
              <strong>Repite Anual: </strong>{{ this.feriado.repite_anual }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :to="'/feriado/' + this.feriado.id + '/editar'"
          ><v-icon left darf>mdi-pencil</v-icon>Editar</v-btn
        >
        <v-btn color="error" @click="handleDialog"
          ><v-icon left darf>mdi-delete</v-icon>Eliminar</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ConfirmDialog from "./ConfirmDialog.vue";
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "FeriadoCard",
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      loading: true,
      dialogTitle: "Confirmar Eliminar",
      dialogText: "¿Desea eliminar el feriado?",
      dialogYes: "Eliminar",
      dialogNo: "Cancelar",
      dialogId: null,
      error: "",
    };
  },
  created() {},
  methods: {
    handleDialog() {
      this.$refs.confirmDialog.dialog = true;
      this.dialogId = this.feriado.id;
    },
    handleDialogYes(id) {
      //console.log(id);
      PresentismoService.deleteFeriado(id)
        .then((response) => {
          this.$router.push({
            name: "Feriados",
          });
        })
        .catch((error) => {
          if (error.response.status === 404)
            this.error = "Error: Registro no encontrado.";
          else if (error.response.status === 405)
            this.error = "Error: El registro está protegido.";
        });
    },
  },
  props: {
    feriado: {
      type: Object,
      required: true,
    },
  },
  computed: {},
};
</script>

<style scoped>
.person-name {
  word-break: break-word;
}
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
.access-detail-p {
  font-size: 1.5em;
}

.access-detail-blk {
  margin-top: 12px;
}

.access-subtitle {
  margin-left: 15px;
}

.access-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>
