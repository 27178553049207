<template>
  <v-container>
    <v-row>
      <v-col style="display: flex">
        <v-btn large icon :to="{ name: 'Feriados' }"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <span class="text-h4">Detalle de Feriado</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <br />
    <div v-if="feriado">
      <FeriadoCard :feriado="feriado"> </FeriadoCard>
    </div>
  </v-container>
</template>

<script>
import FeriadoCard from "../components/FeriadoCard.vue";
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "FeriadoDetalle",
  components: {
    FeriadoCard,
  },
  data() {
    return {
      feriado: null,
      foto: null,
    };
  },
  props: ["id"],
  created() {
    PresentismoService.getFeriado(this.id)
      .then((response) => {
        this.feriado = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
